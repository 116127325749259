import React from "react";

import '../styles/OtherPodcasts.css';
import PodcastCard from "./PodcastCard";

const OtherPodcasts = () => {
  return (
    <div className="otherPodcasts">
      <h3 className="centerHead"><a href="https://interactives.stuff.co.nz/podcasts/">More podcasts from Stuff</a></h3>
      <div className="otherPodsList">
        <PodcastCard>
          <a href="https://listen.stuff.co.nz/thetrialnz?c=LZhCORlqpC0251AqRCwMZw&h=5171e1e31fc073293">
            <img src="https://static3.stuff.co.nz/the-trial-pod-29dcd8b8.jpg" alt="The Trial logo"></img>
            <div className="otherPodTitle">The Trial</div>
            <div className="otherPodAuthor"></div>
          </a>
        </PodcastCard>

        <PodcastCard>
          <a href="https://stuff.supportingcast.fm/tlb">
            <img src="https://static3.stuff.co.nz/the-lost-boy-f2acd985.jpg" alt="The Lost Boy logo"></img>
            <div className="otherPodTitle">The Lost Boy</div>
            <div className="otherPodAuthor"></div>
          </a>
        </PodcastCard>

        <PodcastCard>
          <a href="https://interactives.stuff.co.nz/the-girls-uninterrupted-podcast/">
            <img src="https://static3.stuff.co.nz/tgu-pod-c098598c.jpg" alt="The Girls Uninterrupted logo"></img>
            <div className="otherPodTitle">The Girls Uninterrupted</div>
            <div className="otherPodAuthor"></div>
          </a>
        </PodcastCard>

        <PodcastCard>
          <a href="https://interactives.stuff.co.nz/tova-podcast/">
            <img src="https://static3.stuff.co.nz/tova-pod-74f89c79.jpg" alt="Generally Famous logo"></img>
            <div className="otherPodTitle">Tova</div>
            <div className="otherPodAuthor"></div>
          </a>
        </PodcastCard>

      </div>
    </div>

  )
}

export default OtherPodcasts;