import React from "react";
// import { BnzLogo } from "./logos/BnzLogo";

import '../styles/IntroBanner.css';
const IntroBanner = () => {
  return (
    <div className="introBanner">
      <div className="introBanner-inner">
        <div className="tagLine">Worth talking&nbsp;about</div>
        <div className="introText">Newsable is all about what everyone's talking about. You can listen here, on <a href="https://podcasts.apple.com/us/podcast/newsable/id1673192369">Apple</a>,  <a href="https://open.spotify.com/show/1KD82iNSlI4X03HjjqEM1m">Spotify</a> or wherever else you get your pods. Get in touch: <a href="mailto:newsable@stuff.co.nz">newsable@stuff.co.nz</a></div>
        <div className="sponsorLogo">
          {/* comment out the BnzLogo component if a sponsor-free version of the page is needed */}
          {/* <BnzLogo width="103px" height="50px" colour="#000000" /> */}
        </div>
      </div>
    </div>
  );
}

export default IntroBanner;