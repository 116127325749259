import React from "react";

import '../styles/HeroImage.css';

const HeroImage = () => {
  return (
    <div className="heroImage">
      <img src="img/generic-hero.png" alt="" />
    </div>
  )
}

export default HeroImage;