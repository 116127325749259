import React, { useState, useEffect } from "react";
import Header from "./Header";
import TopSection from "./TopSection";
import IntroBanner from "./IntroBanner";
import PreviousBanner from "./PreviousBanner";
import PreviousOptions from "./PreviousOptions";
import PreviousEpisodes from "./PreviousEpisodes";
// import SignUp from "./SignUp";
import AllFollow from "./AllFollow";
import AdSlot from "./AdSlot";
// import Credits from "./Credits";
import OtherPodcasts from "./OtherPodcasts";
import Footer from "./Footer";

import '../styles/App.css';

// test id
// const apiURL = 'https://ixbwx8q9nj.execute-api.ap-southeast-2.amazonaws.com/stuffAudio/podcasts/a88b5970-7fde-11ed-a08b-6fe054c5a6e4/episodes?orderBy=ep_pubdate&status=published';

//LIVE ID
const apiURL = 'https://96xdb6tqk7.execute-api.ap-southeast-2.amazonaws.com/stuffAudio/podcasts/0137501a-b18a-11ed-8267-1fc228cbf4fd/episodes?orderBy=ep_pubdate&status=published';

const getData = async (url) => {
  try {
    const res = await fetch(url);
    const data = await res.json();
    // console.log(data);
    return data;
  } catch (err) {
    console.error(err)
    return { "error": err }
  }
}

function App() {
  const [episodeArr, setEpisodeArr] = useState([]);
  const [prevEpisodes, setPrevEpisodes] = useState([]);
  const [showPrevImages, setShowPrevImages] = useState(true);

  // used in PreviousOptions.js
  const setShowImages = () => {
    setShowPrevImages((prevState) => {
      return !prevState;
    })
  }

  useEffect(() => {
    getData(apiURL).then((data) => {
      //copy the episodes array
      const prevEps = [...data];
      // remove first episode
      prevEps.shift();
      setEpisodeArr(data);
      setPrevEpisodes(prevEps);
    });
  }, []);

  return (
    <React.Fragment>
      <div className="App">
        <Header />
        <main>
          <TopSection ep={episodeArr[0]} />
          <IntroBanner />
          <PreviousBanner prevList={prevEpisodes} />
          <PreviousOptions prevList={prevEpisodes} setImages={setShowImages} showImages={showPrevImages} />
          <PreviousEpisodes prevList={prevEpisodes} showImages={showPrevImages} />
          {/* <SignUp /> */}
          <AdSlot adID="div-gpt-ad-6385133-1" />
          <AllFollow />
          {/* <Credits /> */}
          <OtherPodcasts />
        </main>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default App;
